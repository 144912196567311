export default {
    title: "Realms: what's on your mind?",
    description: "Realms are safe spaces to talk about cool things.",
    openGraph: {
        type: 'website',
        locale: 'en_US',
        title: 'Realms',
        description: "what's on your mind?",
        images: [
            {
                url: 'https://anon-prod.s3-us-west-1.amazonaws.com/branding/realms-splash.png',
                width: 1200,
                height: 630,
                alt: 'Realms splash',
            },
        ],
        site_name: 'Realms',
    },
    twitter: {
        cardType: 'summary_large_image',
    }
}