import React from 'react';
import App from 'next/app';
import Context from '../components/Context';
import Cookies from 'js-cookie'
import axios from 'axios'
import psl from 'psl';
import Router from 'next/router';
import { trackPageView } from '../helpers';
import { DefaultSeo } from 'next-seo';
import SEO from '../helpers/seo.config'
import * as Sentry from '@sentry/browser';
import '../index.scss'
import { auth, firebase } from '../firebase'
import { track, resetIdentity } from '../helpers/segmentHelpers'
const api = process.env.API;

Sentry.init({dsn: process.env.SENTRY_DSN});

export default class CosmonautApp extends App {

    state = {
        authenticatedUser: Cookies.getJSON('authenticatedUser') || null,
        currentUser: null,
        currentBlog: null,
        hideSuperNavBar: false,
        readyToRender: false,
        domain: "",
        isCustomDomain: false
    };

    async componentDidMount() {
        this.setState({
            readyToRender: true
        });

        Router.onRouteChangeComplete = url => {
            trackPageView(url);
        };

        // var parsed = psl.parse(location.hostname);
        // var domain = parsed.domain || "localhost";
        // var subdomain = parsed.subdomain;

        //FUTURE: Should we even be getting current blog here? Probably wasted calls for certain pages
        // if(domain === "imprint.to") {
        //     //use subdomain
        //     this.setState({
        //         domain: subdomain,
        //         isCustomDomain: false
        //     });
        //     this.getCurrentBlog(subdomain, false, null);
        // }
        // else if(domain.includes("localhost")) {
        //     var sub = /:\/\/([^\/]+)/.exec(window.location.href)[1].split('.')[0];
        //     this.setState({
        //         domain: sub,
        //         isCustomDomain: false
        //     })
        //     this.getCurrentBlog(sub, false, null);
        // }
        // else {
        //     //use hostname
        //     var customDomain = psl.get(location.hostname);
        //     this.setState({
        //         domain: customDomain,
        //         isCustomDomain: true
        //     })
        //     //search if host matches up with custom domain in DB
        //     this.getCurrentBlog(customDomain, true, null);
        // }
        //get user
        this.getCurrentUser();
    }

    componentDidCatch(error, errorInfo) {
        if(process.env.NODE_ENV === 'production') {
            Sentry.withScope((scope) => {
                Object.keys(errorInfo).forEach((key) => {
                    scope.setExtra(key, errorInfo[key]);
                });
                Sentry.captureException(error);
            });
        }
        super.componentDidCatch(error, errorInfo);
    }

    render() {
        const { authenticatedUser } = this.state;
        const { currentUser } = this.state;
        const { currentBlog } = this.state;
        const { domain } = this.state;
        const { isCustomDomain } = this.state;

        const value = {
            currentUser,
            currentBlog,
            authenticatedUser,
            domain,
            isCustomDomain,
            actions: {
                signIn: this.signIn,
                signOut: this.signOut,
                getCurrentUser: this.getCurrentUser,
                hideSuperNavBar: this.hideSuperNavBar
            },
        };

        const { Component, pageProps } = this.props;
        return (
            <React.Fragment>
                <DefaultSeo
                    {...SEO}
                />
                <Context.Provider value={value}>
                    <Component {...pageProps} />
                </Context.Provider>  
            </React.Fragment>
        );
    }

    hideSuperNavBar = () => {
        this.setState({
            hideSuperNavBar: true
        });
    }
    
    signIn = (user) => {
        console.log("context signin");
        console.log(user);
        if(user) {
            this.setState(() => {
                return {
                    authenticatedUser: user,
                };
            });
            Cookies.set('authenticatedUser', JSON.stringify(this.state.authenticatedUser), {expires: 100});
        }
    }

    signOut = (credential) => {
        auth.signOut()
            .then(() => {
                this.setState({ authenticatedUser: null });
                Cookies.remove('authenticatedUser');
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
                alert('Error: could not sign out successfully.');
            });
            
    }

    getCurrentUser = async (callback) => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                // User is signed in.
                await axios.get(api + '/users/current/' + user.uid)
                    .then((res) => {
                        this.setState({
                            currentUser: res.data.user
                        });
                        callback(res.data.user);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    }
}
