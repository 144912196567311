export function track(eventName, trackData) {
    global.analytics.track(eventName, {
      trackData
    });
}

export function identify(userId, email, username) {
    global.analytics.identify(userId, {
        "email": email,
        "username": username
    });
}

export function resetIdentity() {
    global.analytics.reset();
}